export const getCompanyKeys = function (company) {
  const keys = {};

  availableCompanyKeys.forEach((key) => {
    if (company[key.id]) {
      keys[key.id] = company[key.id];
    }
  });

  return keys;
};

export const getEnergyLabelKeys = function (energyLabel) {
  const keys = {};

  availableEnergyLabelKeys.forEach((key) => {
    if (energyLabel[key.id]) {
      keys[key.id] = energyLabel[key.id];
    }
  });

  return keys;
};

export const getPlanKeys = function (plan) {
  const keys = {};

  availablePlanKeys.forEach((key) => {
    if (plan[key.id]) {
      keys[key.id] = plan[key.id];
    }
  });

  return keys;
};

export const getSoilConKeys = function (soilCon) {
  const keys = {};

  availableSoilConKeys.forEach((key) => {
    if (soilCon[key.id]) {
      keys[key.id] = soilCon[key.id];
    }
  });

  return keys;
};

export const getMortgatesKeys = function (mortgage) {
  const keys = {};

  availableMortgatesKeys.forEach((key) => {
    if (mortgage[key.id]) {
      keys[key.id] = mortgage[key.id];
    }
  });

  return keys;
};

export const availableMortgatesKeys = [
  {
    id: "bfeNumber",
    text: "BFE nummer",
    example: "6014180",
  },
];

export const availableSoilConKeys = [
  {
    id: "ownersGuildCode",
    text: "Landsejerlav",
    example: "125467",
  },
  {
    id: "matrikelNumber",
    text: "Matrikelnummer",
    example: "25h",
  },
];

const availablePlanKeys = [
  {
    id: "type",
    text: "Type",
    example: "municipality",
  },
  {
    id: "planId",
    text: "Plan id",
    example: "10466010",
  },
  {
    id: "planName",
    text: "Plan navn",
    example: "Brogårdsvej og Gentoftegade",
  },
  {
    id: "planNumber",
    text: "Plan nummer",
    example: "5.B46",
  },
  {
    id: "zone",
    text: "Zone",
    example: "1",
  },
  {
    id: "district",
    text: "Distrikt",
    example: "Distrikt",
  },
  {
    id: "statusCode",
    text: "Status kode",
    example: "V",
  },
  {
    id: "statusText",
    text: "Status text",
    example: "Vedtaget",
  },
  {
    id: "typeCode",
    text: "Type kode",
    example: " 10.1",
  },
  {
    id: "typeText",
    text: "Type text",
    example: "Kommuneplanrammer",
  },
  {
    id: "generalUsageCode",
    text: "Generel anvendelses kode",
    example: "11",
  },
  {
    id: "generalUsageText",
    text: "Generel anvendelses text",
    example: "Boligområde",
  },
  {
    id: "adoptionDate",
    text: "Adopterings dato",
    example: "2021-08-30",
  },
  {
    id: "proposalDate",
    text: "Forslags dato",
    example: "2021-04-26",
  },
  {
    id: "terminationDate",
    text: "Afslutnings dato",
    example: "2021-04-26",
  },
  {
    id: "publicationDate",
    text: "Udgivelses dato",
    example: "2021-04-26",
  },
  {
    id: "startDate",
    text: "Start dato",
    example: "2021-04-26",
  },
  {
    id: "endDate",
    text: "Slut dato",
    example: "2021-04-26",
  },
  {
    id: "lastUpdated",
    text: "Sidst opdateret",
    example: "2021-04-26",
  },
  {
    id: "maxBuildingPercentage",
    text: "Max bygnings procent",
    example: "25",
  },
  {
    id: "maxBuildingPercentageCalculation",
    text: "Max bygnings procent udregnelse",
    example: "2",
  },
  {
    id: "maxBuiltArea",
    text: "Max bebygget areal",
    example: "40",
  },
  {
    id: "maxBuildingVolume",
    text: "Max bygnings volumen",
    example: "50",
  },
  {
    id: "maxNumberOfFloors",
    text: "Max antal etager",
    example: "2",
  },
  {
    id: "maxHeight",
    text: "Max højde",
    example: "28",
  },
  {
    id: "minSize",
    text: "Min størrelse",
    example: "132",
  },
  {
    id: "maxBuildingPercentagePerProperty",
    text: "Max bygningsprocent pr. ejendom",
    example: "44",
  },
];

export const availableEnergyLabelKeys = [
  {
    id: "propertyNumber",
    text: "Ejendomsnummer",
    example: "574954",
  },
  {
    id: "buildingNumber",
    text: "Bygningsnummer",
    example: "1",
  },
  {
    id: "serialNumber",
    text: "Serienummer",
    example: "311258828",
  },
  {
    id: "classification",
    text: "Klassifikation",
    example: "C",
  },
  {
    id: "statusCode",
    text: "Status kode",
    example: "1",
  },
  {
    id: "statusText",
    text: "Status text",
    example: "VALID",
  },
  {
    id: "basedOn",
    text: "Baseret på",
    example: "CalculatedConsumption",
  },
  {
    id: "usage",
    text: "Anvendelse",
    example: "NonResidental",
  },
  {
    id: "consultantName",
    text: "Konsulent navn",
    example: "Jens Jensen",
  },
  {
    id: "companyName",
    text: "Virksomheds navn",
    example: "Bang & Beenfeldt A/S",
  },
  {
    id: "companyNumber",
    text: "Virksomheds nummer",
    example: "600272",
  },
  {
    id: "validFrom",
    text: "Gyldig fra",
    example: "2017-07-04",
  },
  {
    id: "validTo",
    text: "Gyldig til",
    example: "2027-07-04",
  },
];

export const availableCompanyKeys = [
  {
    id: "cvr",
    text: "CVR-nummer",
    example: "41314281",
  },
  {
    id: "advertisingProtected",
    text: "Reklamebeskyttet",
    example: "true",
  },
  {
    id: "capital",
    text: "Kapital",
    example: "1000000",
  },
  {
    id: "email",
    text: "Email",
    example: "hello@estaid.dk",
  },
  {
    id: "establishedAt",
    text: "Etableret",
    example: "2020-04-23",
  },
  {
    id: "lastUpdated",
    text: "Sidst opdateret",
    example: "2022-05-02",
  },
  {
    id: "mainBranchCode",
    text: "Hovedbranche kode",
    example: "582900",
  },
  {
    id: "mainBranchText",
    text: "Hovedbranche text",
    example: "Anden udgivelse af software",
  },
  {
    id: "name",
    text: "Virksomheds Navn",
    example: "Estaid ApS",
  },
  {
    id: "phoneNumber",
    text: "Telefonnummer",
    example: "51842410",
  },
  {
    id: "status",
    text: "Status",
    example: "NORMAL",
  },
  {
    id: "typeText",
    text: "Type Text",
    example: "Anpartsselskab",
  },
  {
    id: "zipCode",
    text: "Postnummer",
    example: "1253",
  },
  {
    id: "street",
    text: "Vejnavn",
    example: "Toldbodgade",
  },
  {
    id: "city",
    text: "By",
    example: "København K",
  },
  {
    id: "countryCode",
    text: "Landekode",
    example: "DK",
  },
  {
    id: "door",
    text: "Dør nr.",
    example: "th",
  },
  {
    id: "floor",
    text: "Etage",
    example: "1",
  },
  {
    id: "houseLetterFrom",
    text: "Husbogstav fra",
    example: "C",
  },
  {
    id: "houseLetterTo",
    text: "Husbogstav til",
    example: "D",
  },
  {
    id: "houseNumberFrom",
    text: "Husnummer fra",
    example: "51",
  },
  {
    id: "houseNumberTo",
    text: "Husnummer til",
    example: "52",
  },
];
